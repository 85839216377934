// Generated by Framer (26d4882)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["XxnIhOuSY", "iMYHHazV8"];

const serializationHash = "framer-KeAfj"

const variantClassNames = {iMYHHazV8: "framer-v-lhp64o", XxnIhOuSY: "framer-v-mn01z2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Closed: "XxnIhOuSY", Open: "iMYHHazV8"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "XxnIhOuSY"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "XxnIhOuSY", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-KeAfj", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-mn01z2", className)} data-framer-name={"Closed"} layoutDependency={layoutDependency} layoutId={"XxnIhOuSY"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({iMYHHazV8: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-ol2nkd"} data-framer-name={"Frame"} layoutDependency={layoutDependency} layoutId={"nz6l9XU0x"} style={{rotate: 0}} variants={{iMYHHazV8: {rotate: -180}}}><SVG className={"framer-3cjzgp"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"XU2W6rDQ5"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"#aaa\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={892255882} withExternalLayout {...addPropertyOverrides({iMYHHazV8: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"var(--token-84a40457-4278-48a9-918c-5ea0d323b01b, rgb(58, 58, 64)) /* {&quot;name&quot;:&quot;Neutral/700&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>", svgContentId: 3274914030}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-KeAfj [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KeAfj .framer-hltfy5 { display: block; }", ".framer-KeAfj .framer-mn01z2 { height: 14px; overflow: visible; position: relative; width: 14px; }", ".framer-KeAfj .framer-ol2nkd { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-KeAfj .framer-3cjzgp { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"iMYHHazV8":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const Framerw7iCQk3jB: React.ComponentType<Props> = withCSS(Component, css, "framer-KeAfj") as typeof Component;
export default Framerw7iCQk3jB;

Framerw7iCQk3jB.displayName = "Caret";

Framerw7iCQk3jB.defaultProps = {height: 14, width: 14};

addPropertyControls(Framerw7iCQk3jB, {variant: {options: ["XxnIhOuSY", "iMYHHazV8"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerw7iCQk3jB, [])